import $ from 'jquery';

class MenuToggle {
  constructor() {
    this.indexArray = ['first', 'second', 'third', 'fourth'];
    this.$menuToggle = $('.menu-toggle');
    this.index = 0;

    this.bindEvents();
  }

  bindEvents() {
    $('.menu__trigger, .menu-page__title').on('click', this.onClick.bind(this));
    $('.menu-page__content').on('click', this.reset.bind(this));
  }

  onClick(e) {
    e.preventDefault();
    let $el = $(e.currentTarget).parent('.menu-page');
    let index = $el.index();
    let $currentLink = $el;

    $('.js--menu-toggle').not($el).removeClass('is-active');
    $el.addClass('is-active');

    this.$menuToggle.removeClass().addClass(`menu-toggle ${this.indexArray[index]}-is-open`);

    this.index = index;

    if(!$('body').hasClass('menu--is-active')){
      $('body').addClass('menu--is-active');
    }
  }

  reset(e) {
    e.preventDefault();
    let $el = $(e.currentTarget).parent('.menu-page');

    $el.removeClass('is-active');
    this.$menuToggle.removeClass(`${this.indexArray[this.index]}-is-open`);

    if($('body').hasClass('menu--is-active')) {
      $('body').removeClass('menu--is-active');
    }
  }
}

export default MenuToggle;
