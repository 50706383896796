import $ from 'jquery';

class SideNav {
  constructor() {
    this.$jsNavClick = $('.js--nav-click');
    this.bindEvents();
  }

  bindEvents() {
    this.$jsNavClick.on('click', this.toggleNav.bind(this));
  }

  toggleNav(e) {
    e.preventDefault();
    let el = $(e.currentTarget);

    el.parents('.side-nav').toggleClass('nav-is-open');
  }
}

export default SideNav;
