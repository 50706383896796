import $ from 'jquery';
import { TweenMax, Expo, TimelineMax, TimelineLite } from 'gsap';
import animations from './animations';

class Tangrams {
  constructor() {
    const timelineOptions = {
      paused: true,
      repeat: -1,
      repeatDelay: 1,
      startTime: 2
    };

    this.desktopMaster = new TimelineMax(timelineOptions);
    this.mobileMaster = new TimelineMax(timelineOptions);

    this.$window = $(window);
    this.$tangramItem = $('.js--tangram-item');

    this.bindEvents();
    this.resize();
    this.buildTimeLines();
  }

  bindEvents() {
    this.$window.on('resize', this.resize.bind(this));
  }

  resize() {
    if(this.$window.width() > 570) {
      this.mobileMaster.restart();
      this.mobileMaster.stop();

      if(!this.desktopMaster.isActive()) {
        this.desktopMaster.play();
      }
    } else {
      this.desktopMaster.restart();
      this.desktopMaster.stop();

      if(!this.mobileMaster.isActive()) {
        this.mobileMaster.play();
      }
    }
  }

  buildTimeLines() {
    const timelineOptions = {
      yoyo: true,
      repeat: 1,
      repeatDelay: 2
    };

    Object.keys(animations).forEach((key) => {
      let obj = animations[key],
          shuffledItems = this.shuffleItems(obj),
          dtl = new TimelineMax(timelineOptions),
          mtl = new TimelineMax(timelineOptions);

      shuffledItems.map((item, i) => {
        dtl.to(item.el, 0.6, {
          ease: Expo.easeInOut,
          x: item.desktop.x,
          y: item.desktop.y,
          rotationZ: item.desktop.rotate
        }, '-=0.5');
        mtl.to(item.el, 0.6, {
          ease: Expo.easeInOut,
          x: item.mobile.x,
          y: item.mobile.y,
          rotationZ: item.mobile.rotate
        }, '-=0.5');
      });

      this.desktopMaster.add(dtl, '+=2');
      this.mobileMaster.add(mtl, '+=2');
    });
  }

  shuffleItems(arr) {
    return this.shuffle(arr);
  }

/**
 * Fisher-Yates shuffle algorithm
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
}

export default Tangrams;
