import $ from 'jquery';
import { TweenMax, Linear, TimelineMax } from 'gsap';

class Landing {
  constructor() {
    this.$window = $(window);
    this.$hover = $('.js--landing-hover');
    this.aspect = 1668 / 772;
    this.maxWidth = 0;
    this.bindEvents();
    this.timeline = new TimelineMax({ repeat: -1 });
    this.resizeImage();
    this.animate();
  }

  bindEvents() {
    this.$window.on('resize', this.resize.bind(this));
    this.$hover.hover(() => {
      this.timeline.pause();
    }, () => {
      this.timeline.play();
    });
  }

  resize() {
    this.resizeImage();
    this.update();
  }

  resizeImage() {
    let windowHeight = $(window).height(),
        windowWidth = $(window).width(),
        aspect = this.aspect;

    $('.js--resize-content').css({
      height: windowHeight,
      width: (windowHeight * aspect) * 3
    });

    this.maxWidth = windowHeight * aspect;
  }

  animate() {
    let aspect = this.aspect,
        maxWidth = this.maxWidth;

    this.timeline.to('.js--resize-content', 15, {
      x: (maxWidth) * -1,
      ease: Linear.easeNone
    });
  }

  update() {
    TweenMax.set('.js--resize-content', {clearProps: 'x'});
    this.timeline.kill();
    this.timeline = null;
    this.timeline = new TimelineMax({ repeat: -1 });

    let aspect = this.aspect,
        maxWidth = this.maxWidth;

    this.timeline.to('.js--resize-content', 15, {
      x: ($(window).height() * aspect) * -1,
      ease: Linear.easeNone
    });
  }
}

export default Landing;
