import $ from 'jquery';
import Swiper from 'swiper';

class Slider {
  constructor() {
    this.initSwiper('.carousel');
  }

  initSwiper(el) {
    let swiper = new Swiper (el, {
      direction: 'horizontal',
      loop: true,
      grabCursor: true,
      navigation: {
        nextEl: '.slider-button-next',
        prevEl: '.slider-button-prev'
      }
    });
  }
}

export default Slider;
