import $ from 'jquery';

import Expander from './modules/expander';
import Landing from './modules/landing';
import Maps from './modules/maps';
import MenuToggle from './modules/menu-toggle';
import SideNav from './modules/side-nav';
import Slider from './modules/slider';
import Tangrams from './modules/tangrams';
import 'lazysizes/plugins/respimg/ls.respimg.js';
import Lazysizes from 'lazysizes';

window.onload = () => {
  new Expander();

  if($('.landing-page').length) {
    new Landing();
  }

  new Maps();
  new MenuToggle();
  new SideNav();
  new Slider();
  new Tangrams();
};
