import $ from 'jquery';
import { TweenMax, Expo, TimelineLite } from 'gsap';

class Expander {
  constructor() {
    this.$trigger = $('.js--expander');
    this.$eventAll = $('.js--expander-content');
    this.isClosed = false;
    this.bindEvents();
  }

  bindEvents() {
    this.$trigger.on('click', this.expand.bind(this));
  }

  toggle(e) {
    let el = $(e.currentTarget);

    el.parents('.calendar-event').addClass('is-expanded');
    if(el.parents('.calendar-event').hasClass('is-expanded')) {

    }
    this.expand(el);
  }

  expand(e) {
    let el = $(e.currentTarget);
    let parent = el.parents('.calendar-event');
    let $content = el.parents('.calendar-event').find('.js--expander-content');

    if(!el.parents('.calendar-event').hasClass('is-expanded')) {
      el.parents('.calendar-event').addClass('is-expanded');
      TweenMax.set($content, {height: 'auto' });

      TweenMax.from($content, 0.5, {
        ease: Expo.easeInOut,
        height: 0,
        onComplete: () => {
          parent.find('.calendar-more').text('Close');
        }
      });
    } else {
      TweenMax.to($content, 0.5, {
        ease: Expo.easeInOut,
        height: 0,
        onComplete: () => {
          parent.find('.calendar-more').text('View Event');
          el.parents('.calendar-event').removeClass('is-expanded');
        }
      });
    }
  }
}

export default Expander;
