const animations = {
  squirrel: [
    {
      el: '.rectangle',
      desktop: {
        x: 170,
        y: 0,
        rotate: 90
      },
      mobile: {
        x: 90,
        y: 0,
        rotate: 90
      }
    },
    {
      el: '.b--1',
      desktop: {
        x: 276,
        y: -56,
        rotate: 90
      },
      mobile: {
        x: 26,
        y: 17,
        rotate: -90
      }
    },
    {
      el: '.b--2',
      desktop: {
        x: 36,
        y: -25,
        rotate: -90
      },
      mobile: {
        x: 138,
        y: -49,
        rotate: -270
      }
    },
    {
      el: '.rectangle-2',
      desktop: {
        x: 135,
        y: -40,
        rotate: 0
      },
      mobile: {
        x: 70,
        y: -15,
        rotate: 0
      }
    },
    {
      el: '.corner-1',
      desktop: {
        x: 85,
        y: -1,
        rotate: 180
      },
      mobile: {
        x: 47,
        y: 0,
        rotate: 180
      }
    },
    {
      el: '.half-circle-1',
      desktop: {
        x: -64,
        y: -48,
        rotate: 90
      },
      mobile: {
        x: -30,
        y: -23,
        rotate: 90
      }
    },
    {
      el: '.half-circle-2',
      desktop: {
        x: -6,
        y: -127,
        rotate: -90
      },
      mobile: {
        x: -2,
        y: -56,
        rotate: -90
      }
    },
    {
      el: '.half-circle-3',
      desktop: {
        x: -76,
        y: 0,
        rotate: 180
      },
      mobile: {
        x: -39,
        y: 0,
        rotate: 180
      }
    },
    {
      el: '.corner-2',
      desktop: {
        x: -264,
        y: 82,
        rotate: 270
      },
      mobile: {
        x: -126,
        y: 40,
        rotate: -90
      }
    },
    {
      el: '.corner-3',
      desktop: {
        x: -220,
        y: 0,
        rotate: 180
      },
      mobile: {
        x: -104,
        y: 1,
        rotate: 180
      }
    }
  ],
  octopus: [
    {
      el: '.rectangle',
      desktop: {
        x: 160,
        y: -56,
        rotate: 90
      },
      mobile: {
        x: 90,
        y: -30,
        rotate: 90
      }
    },
    {
      el: '.b--1',
      desktop: {
        x: 26,
        y: 20,
        rotate: -90
      },
      mobile: {
        x: 27,
        y: 6,
        rotate: -90
      }
    },
    {
      el: '.b--2',
      desktop: {
        x: 62,
        y: -9,
        rotate: 90
      },
      mobile: {
        x: 44,
        y: -6,
        rotate: 90
      }
    },
    {
      el: '.rectangle-2',
      desktop: {
        x: 43,
        y: -15,
        rotate: 90
      },
      mobile: {
        x: 32,
        y: -9,
        rotate: 90
      }
    },
    {
      el: '.corner-1',
      desktop: {
        x: 112,
        y: -15,
        rotate: -90
      },
      mobile: {
        x: 67,
        y: -7,
        rotate: -90
      }
    },
    {
      el: '.half-circle-1',
      desktop: {
        x: -75,
        y: -102,
        rotate: 90
      },
      mobile: {
        x: -29,
        y: -53,
        rotate: 90
      }
    },
    {
      el: '.half-circle-2',
      desktop: {
        x: -19,
        y: -20,
        rotate: -90
      },
      mobile: {
        x: 0,
        y: -12,
        rotate: -90
      }
    },
    {
      el: '.half-circle-3',
      desktop: {
        x: -177,
        y: 32,
        rotate: -90
      },
      mobile: {
        x: -79,
        y: 13,
        rotate: -90
      }
    },
    {
      el: '.corner-2',
      desktop: {
        x: -375,
        y: 65,
        rotate: 0
      },
      mobile: {
        x: -174,
        y: 30,
        rotate: 0
      }
    },
    {
      el: '.corner-3',
      desktop: {
        x: -113,
        y: -15,
        rotate: -90
      },
      mobile: {
        x: -47,
        y: -7,
        rotate: -90
      }
    }
  ],
  soup: [
    {
      el: '.rectangle',
      desktop: {
        x: 159,
        y: -49,
        rotate: -90
      },
      mobile: {
        x: 92,
        y: -29,
        rotate: 90
      }
    },
    {
      el: '.b--1',
      desktop: {
        x: 22,
        y: -66,
        rotate: -90
      },
      mobile: {
        x: 26,
        y: -39,
        rotate: -90
      }
    },
    {
      el: '.b--1',
      desktop: {
        x: 22,
        y: -66,
        rotate: -90
      },
      mobile: {
        x: 26,
        y: -39,
        rotate: -90
      }
    },
    {
      el: '.b--2',
      desktop: {
        x: 302,
        y: -34,
        rotate: -90
      },
      mobile: {
        x: 166,
        y: -19,
        rotate: -90
      }
    },
    {
      el: '.rectangle-2',
      desktop: {
        x: 42,
        y: 40,
        rotate: 90
      },
      mobile: {
        x: 33,
        y: 17,
        rotate: 90
      }
    },
    {
      el: '.corner-1',
      desktop: {
        x: 50,
        y: -89,
        rotate: -45
      },
      mobile: {
        x: 38,
        y: -47,
        rotate: -45
      }
    },
    {
      el: '.half-circle-1',
      desktop: {
        x: -76,
        y: -96,
        rotate: 90
      },
      mobile: {
        x: -28,
        y: -52,
        rotate: 90
      }
    },
    {
      el: '.half-circle-2',
      desktop: {
        x: -140,
        y: -3,
        rotate: 90
      },
      mobile: {
        x: -58,
        y: -6,
        rotate: 90
      }
    },
    {
      el: '.half-circle-3',
      desktop: {
        x: -58,
        y: 37,
        rotate: -90
      },
      mobile: {
        x: -17,
        y: 15,
        rotate: -90
      }
    },
    {
      el: '.corner-2',
      desktop: {
        x: -123,
        y: -40,
        rotate: 45
      },
      mobile: {
        x: -45,
        y: -23,
        rotate: 45
      }
    },
    {
      el: '.corner-3',
      desktop: {
        x: -129,
        y: -89,
        rotate: 134
      },
      mobile: {
        x: -49,
        y: -47,
        rotate: 134
      }
    },
  ],
  pelican: [
    {
      el: '.rectangle',
      desktop: {
        x: 159,
        y: -33,
        rotate: 0
      },
      mobile: {
        x: 77,
        y: -14,
        rotate: 0
      }
    },
    {
      el: '.b--1',
      desktop: {
        x: 100,
        y: -131,
        rotate: -90
      },
      mobile: {
        x: 50,
        y: -62,
        rotate: -90
      }
    },
    {
      el: '.b--1',
      desktop: {
        x: 100,
        y: -131,
        rotate: -90
      },
      mobile: {
        x: 50,
        y: -62,
        rotate: -90
      }
    },
    {
      el: '.b--2',
      desktop: {
        x: 93,
        y: 15,
        rotate: -90
      },
      mobile: {
        x: 45,
        y: 8,
        rotate: -90
      }
    },
    {
      el: '.rectangle-2',
      desktop: {
        x: 124,
        y: -73,
        rotate: 90
      },
      mobile: {
        x: 58,
        y: -34,
        rotate: 90
      }
    },
    {
      el: '.corner-1',
      desktop: {
        x: 32,
        y: -33,
        rotate: 90
      },
      mobile: {
        x: 15,
        y: -14,
        rotate: 90
      }
    },
    {
      el: '.half-circle-1',
      desktop: {
        x: -123,
        y: -33,
        rotate: 0
      },
      mobile: {
        x: -65,
        y: -14,
        rotate: 0
      }
    },
    {
      el: '.half-circle-2',
      desktop: {
        x: -183,
        y: -119,
        rotate: 90
      },
      mobile: {
        x: -91,
        y: -54,
        rotate: 90
      }
    },
    {
      el: '.half-circle-3',
      desktop: {
        x: -135,
        y: -26,
        rotate: -90
      },
      mobile: {
        x: -73,
        y: -11,
        rotate: -90
      }
    },
    {
      el: '.corner-2',
      desktop: {
        x: -196,
        y: 87,
        rotate: 180
      },
      mobile: {
        x: -101,
        y: 42,
        rotate: 180
      }
    },
    {
      el: '.corner-3',
      desktop: {
        x: -234,
        y: 46,
        rotate: -90
      },
      mobile: {
        x: -120,
        y: 23,
        rotate: -90
      }
    }
  ],
  tea: [
    {
      el: '.rectangle',
      desktop: {
        x: 97,
        y: -16,
        rotate: 90
      },
      mobile: {
        x: 62,
        y: -11,
        rotate: 90
      }
    },
    {
      el: '.b--1',
      desktop: {
        x: 283,
        y: -34,
        rotate: 0
      },
      mobile: {
        x: 155,
        y: -24,
        rotate: 0
      }
    },
    {
      el: '.b--2',
      desktop: {
        x: 208,
        y: 15,
        rotate: -90
      },
      mobile: {
        x: 117,
        y: 7,
        rotate: -90
      }
    },
    {
      el: '.rectangle-2',
      desktop: {
        x: 139,
        y: -40,
        rotate: -90
      },
      mobile: {
        x: 82,
        y: -24,
        rotate: -90
      }
    },
    {
      el: '.corner-1',
      desktop: {
        x: 90,
        y: -202,
        rotate: -45
      },
      mobile: {
        x: 60,
        y: -103,
        rotate: -45
      }
    },
    {
      el: '.half-circle-1',
      desktop: {
        x: -136,
        y: -63,
        rotate: 90
      },
      mobile: {
        x: -57,
        y: -34,
        rotate: 90
      }
    },
    {
      el: '.half-circle-1',
      desktop: {
        x: -136,
        y: -63,
        rotate: 90
      },
      mobile: {
        x: -57,
        y: -34,
        rotate: 90
      }
    },
    {
      el: '.half-circle-2',
      desktop: {
        x: -200,
        y: 31,
        rotate: 90
      },
      mobile: {
        x: -87,
        y: 12,
        rotate: 90
      }
    },
    {
      el: '.half-circle-3',
      desktop: {
        x: -121,
        y: 7,
        rotate: -90
      },
      mobile: {
        x: -49,
        y: -1,
        rotate: -90
      }
    },
    {
      el: '.corner-2',
      desktop: {
        x: -177,
        y: -53,
        rotate: 45
      },
      mobile: {
        x: -75,
        y: -30,
        rotate: 45
      }
    },
    {
      el: '.corner-3',
      desktop: {
        x: -187,
        y: -171,
        rotate: 135
      },
      mobile: {
        x: -79,
        y: -87,
        rotate: 135
      }
    }
  ]
};

export default animations;
